import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import Header from './header'
import Helmet from "react-helmet";
import Footer from './footer'
import './layout.css'

const Layout = ({ children, data }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          ...HeaderFragment
          siteMetadata {
            about_me {
              social_handles {
                Twitter
              }
            }
          }
        } 
      }
    `}
    render={data => (
      <>
        <Helmet
            htmlAttributes={{
              lang: `en`
            }}
            title={data.site.siteMetadata.title}
            meta={[
                {
                    name: `description`,
                    content: data.site.siteMetadata.about_me.description,
                },
                {
                    property: `og:title`,
                    content: data.site.siteMetadata.title,
                },
                {
                    property: `og:description`,
                    content: data.site.siteMetadata.about_me.description,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:creator`,
                    content: data.site.siteMetadata.about_me.social_handles["Twitter"],
                },
                {
                    name: `twitter:title`,
                    content: data.site.siteMetadata.title,
                },
                {
                    name: `twitter:description`,
                    content: data.site.siteMetadata.about_me.description,
                },
            ]}
        >
            <html lang="en" />
        </Helmet>
        <Header metaData={data.site.siteMetadata} />
        <main className="min-h-screen">
          {children}
        </main>
        <Footer></Footer>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
