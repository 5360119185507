import React from 'react'
import { Link, graphql } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faProjectDiagram, faBrain, faRss, faAt, faHome } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import style from './header.module.css'
library.add(fab, faBrain, faRss, faAt, faProjectDiagram, faHome)

const toggleNav = (e) => {
  const navbar = document.getElementById("headerNavLinks");
  navbar.classList.toggle(style.headerCollapseOpen)
  navbar.classList.toggle(style.headerCollapseClose)
}

const Header = ({ metaData: { title, about_me } }) => {
  return (
    <header className={style.header}>
      <div className={`${style.headerBrand}`}>
        <Link to="/">{title}</Link>
        <button onClick={toggleNav.bind(this)}>Menu</button>
      </div>
      <nav id="headerNavLinks" className={style.headerCollapseClose}>
        <Link activeClassName={style.linkActive} to="/"><FontAwesomeIcon icon="home" className="md:hidden mr-2 md:mr-0" /> Home</Link>
        <Link activeClassName={style.linkActive} partiallyActive={true} to="/blog"><FontAwesomeIcon icon="rss" className="md:hidden mr-2 md:mr-0" /> Blog</Link>
        <Link activeClassName={style.linkActive} to="/#contact"><FontAwesomeIcon icon="at" className="md:hidden mr-2 md:mr-0" /> Contact</Link>
        <div className={`md:hidden ${style.socialLinks}`}>
          {Object.keys(about_me.social_links).map((platform, i) => (
            <a key={platform} href={about_me.social_links[platform]}>
              <FontAwesomeIcon icon={['fab', platform.toLowerCase()]} className="fa-2x" />
            </a>
          ))}
        </div>
      </nav>
      <div className={`hidden md:inline-flex ${style.socialLinks}`}>
        {Object.keys(about_me.social_links).map((platform, i) => (
          <a key={platform} href={about_me.social_links[platform]}>
            <FontAwesomeIcon icon={['fab', platform.toLowerCase()]} className="fa-2x" />
          </a>
        ))}
      </div>
    </header>
  )
}

export default Header
export const query = graphql`
  fragment HeaderFragment on Site {
    siteMetadata {
      title
      about_me {
        name
        short_description
        social_links {
          Github
          Instagram
          Mastodon
        }
      }
    }
  }
`
