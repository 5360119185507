import React from "react";
import {  useStaticQuery, graphql } from "gatsby";
import { ReactComponent as Logo } from "../../images/logo.svg";
import style from "./footer.module.css";

const Footer = () => {
    const { site: { siteMetadata: { about_me: { name } } } } = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    about_me {
                        name
                    }
                }
            }
        }
    `)

    return (
        <footer className={style.footer}>
            <div className={style.footerContainer}>
                <Logo />
                <p>&copy; 2019 {name}. <br/> Powered by <a href="https://gatsbyjs.org">GatsbyJS</a>. Hosted on <a href="https://netlify.com">Netlify</a>.</p>
            </div>
        </footer>
    )
}

export default Footer;