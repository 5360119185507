import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import AuthorBox from "../components/author-box";
import SEO from "../components/seo";
import contentStyle from "../components/blog/content.module.css";

const BlogPost = ({ data: { post } }) => (
  <Layout>
    <SEO title={`${post.frontmatter.title} | blog`} description={post.excerpt} meta={[{ name: 'og:image', content: post.frontmatter.featureimage !== null ? post.frontmatter.featureimage.childImageSharp.original.src : '' }]} />
    <article className="flex flex-col items-center justify-center max-w-3xl px-4 py-16 mx-auto">
      <h1 className="text-tertiary text-3xl md:text-5xl md:w-4/5 mb-8 text-center">{post.frontmatter.title}</h1>
      <div className="uppercase text-xl font-bold text-grey-dark mb-8 flex flex-row">
        <p className="mr-8">{post.frontmatter.date}</p>
        {post.frontmatter.categories.map((c, i, a) => (
          <>
            <Link key={c} className="text-secondary no-underline" to={`/blog/category/${c}`}>{c}</Link>
            {i !== a.length - 1 ? (<span>,&nbsp;</span>) : ""}
          </>
        ))}
      </div>
      {post.frontmatter.featureimage !== null && (
        <div className="aspect-ratio-16/9 relative w-full shadow-lg" style={{ height: `0` }}>
          <img className="h-full w-full absolute pin-t pin-l" style={{ objectFit: "cover" }} src={post.frontmatter.featureimage.childImageSharp.original.src} alt="" />
        </div>
      )}
      <div className={`md:w-2/3 my-8 ${contentStyle.articleContent}`} dangerouslySetInnerHTML={{ __html: post.html }}></div>
    </article>
    <AuthorBox />
  </Layout>
)

export default BlogPost
export const query = graphql`
query ($slug: String!) {
   post: markdownRemark(frontmatter: { path: { eq: $slug } }) {
    frontmatter {
      title
      date(formatString: "MMM DD, YYYY")
      categories
      tags
      featureimage {
        childImageSharp {
          original {
            src
          }
        }
      }
    }
    excerpt
    html
  }
}
`