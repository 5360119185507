import React from "react";
import { graphql, StaticQuery } from "gatsby";

const AuthorBox = (description) => (
    <StaticQuery
        query={graphql`
            {
                site {
                    siteMetadata {
                        about_me {
                            description
                        }
                    }
                }
                file(relativePath: {eq: "ned.jpg"}) {
                    childImageSharp {
                        fluid(maxWidth: 1800) {
                            src
                        }
                    }
                }
            }
        `} 
        render={({ site, file }) => (
            <div className="bg-grey-lighter">
                <div className="max-w-md mx-auto px-4 py-16 flex flex-col items-center justify-center">
                    <h2 className="lowercase text-2xl mb-4">About the Author</h2>
                    <img className="rounded-full w-1/4 m-8" src={file.childImageSharp.fluid.src} alt=""/>
                    <p className="text-lg text-center">{site.siteMetadata.about_me.description}</p>
                </div>
            </div>
        )}  
    />
);

export default AuthorBox;